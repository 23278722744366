export const chunk = (arr, chunkSize) => {
  return arr.reduce((acc, item, idx) => {
    if (idx % chunkSize === 0) {
      acc.push([item])
    } else {
      acc[acc.length - 1] = [...acc[acc.length - 1], item];
    }
    return acc;
  }, []);
};

export const createDataTree = (idKey, parentIdKey, dataset) => {
  const hashTable = Object.create(null);
  dataset.forEach(aData => hashTable[aData[idKey]] = {...aData, children: []});
  const dataTree = [];
  dataset.forEach(aData => {
    if (aData[parentIdKey]) hashTable[aData[parentIdKey]].children.push(hashTable[aData[idKey]])
    else dataTree.push(hashTable[aData[idKey]])
  });
  return dataTree;
};

export const isEqual = (...objects) => objects.every((obj) => JSON.stringify(obj) === JSON.stringify(objects[0]));

export const getFileName = file_name => {
  const nameParts = file_name.split('.');
  return {
    name: nameParts.splice(0, nameParts.length - 1).join(''),
    ext: String(nameParts[nameParts.length - 1]).toLowerCase(),
  }
}

export const copyTextToClipboard = text => {
  const input = document.createElement('input');
  input.type = 'text';
  input.value = text;

  document.body.appendChild(input);
  input.select();
  document.execCommand('copy');
  document.body.removeChild(input);
}

export const highlightText = (textToHighlight, originalText) => {
  if (originalText.toLowerCase().includes(textToHighlight.toLowerCase().trim())) {
    const startIdx = originalText.toLowerCase().indexOf(textToHighlight[0]?.toLowerCase());
    const replaceText = originalText.substring(startIdx, startIdx + textToHighlight.length);
    return originalText.replaceAll(replaceText, `<span class="font-weight-bold">${replaceText}</span>`);
  }
  return originalText;
}

export const removeSpacesFromString = str => {
  return str ? str.replace(/ /g, '') : '';
};

export const removeHTMLTagFromString = str => {
  // return str ? str.replace(/<[^]*>/g, '') : '';
  return str ? str.replace(/<[^>]*>/g, ' ') : '';
};

export const formatToHTMLMentionMessage = (message = '', tagged_users = []) => {
  let msg = message.toString();
  msg = msg.replaceAll('\n', '<br>')
  if (tagged_users?.length) {
    tagged_users.forEach(user => {
      const searchTarget = `user_id=${user.id}`;
      const regex = new RegExp(searchTarget, 'g');
      const userName = `${removeSpacesFromString(user.first_name)}${removeSpacesFromString(user.last_name) || ''}`.toLowerCase().trim();
      msg = msg.replace(regex, `<span class="mention-username" data-user="${user.id}" contenteditable="false">@${userName}</span>`)
    });

    return msg;
  }
  return msg;
}


export const filterFieldElement = (event) => {
  const target = event.target || event.srcElement;
  const {tagName} = target;
  let flag = true;
  // ignore: isContentEditable === 'true', <input> and <textarea> when readOnly state is false, <select>
  if (
    target.isContentEditable
    || ((tagName === 'INPUT' || tagName === 'TEXTAREA' || tagName === 'SELECT') && !target.readOnly)
  ) {
    flag = false;
  }
  return flag;
}

export const getFileExtension = file_name => {
  return `.${getFileName(file_name).ext}`
}


/**
 * @param {number} bytes
 * @param {number} decimals
 */
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * Get File Thumbnails by Preset Name
 * @param {{url: string, preset_name: string }[]} thumbnails
 * @param {string} preset_name
 * @returns {string|undefined}
 */
export const getFileThumbnails = (thumbnails = [], preset_name = 'lg') => {
  return thumbnails.find(thumbnail => thumbnail.preset_name === preset_name)?.url
}

/**
 * Generates an array of numbers, each being double the previous
 *
 * @param {number} from
 * @param {number} to
 * @returns {number[]} - The generated array of numbers.
 */
export function generateDoubledNumbersArray(from, to) {
  const result = [];
  let currentValue = from;

  while (currentValue <= to) {
    result.push(currentValue);
    currentValue *= 2;
  }

  if (result[result.length - 1] !== to) {
    result.push(to);
  }

  return result;
}

export function buildReviewRevisionLink(data, key) {
  return `/project/${data.id}/modules/design-view?module_id=${data[key].module_id}&style_id=${data[key].style_id}&file_id=${data[key].file_id}&gallery_group_id=${data[key].gallery_group_id}`;
}

export function convertToUnit(
  str,
  unit = "px"
) {
  if (str == null || str === "") {
    return undefined;
  } else if (isNaN(+str)) {
    return String(str);
  } else if (!isFinite(+str)) {
    return undefined;
  } else {
    return `${Number(str)}${unit}`;
  }
}

export function capitalizeText(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Delays execution for the specified number of milliseconds.
 *
 * @param {number} ms - The number of milliseconds to delay.
 * @return {Promise<void>} A promise that resolves after the delay.
 */
export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Format Time To Hours.
 *
 * @time {string} - 'hh:mm:ss'.
 * @return {number}.
 */

export function formatTimeToHours(time) {
  if(!time) {
    return 0
  }
  const parts = time.split(':').map(Number);
  if(parts.length < 3) {
    return 0
  }
  const [hours, minutes, seconds] = parts;
  return Number(hours || 0) + Number(minutes || 0) / 60 + Number(seconds || 0) / 3600;
}

/**
 * Format Time To Days.
 *
 * @time {string} - 'hh:mm:ss'.
 * @return {number}.
 */

export function formatTimeToDays(time) {
  if(!time) {
    return 0
  }
  const parts = time.split(':').map(Number);
  if(parts.length < 3) {
    return 0
  }
  const [hours, minutes, seconds] = parts;
  return (Number(hours || 0) + Number(minutes || 0) / 60 + Number(seconds || 0) / 3600) / 60;
}

/**
 * toFixed to first symbol after dot.
 *
 * @time {number} - 2.0222.
 * @return {number}.
 */

export function toFixedFirstSignificant(value) {
  if (Number.isInteger(value)) return value;
  const decimalPlaces = Math.max(1, -Math.floor(Math.log10(Math.abs(value))));
  return Number(value.toFixed(decimalPlaces) || 0);
}
